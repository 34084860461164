// const API_BASE_URL = 'http://localhost:8080/api'
// const BASE_URL = 'http://localhost:8080'

// const API_BASE_URL = 'https://api.soniagarmar.com/api'
// const BASE_URL = 'https://api.soniagarmar.com'

const API_BASE_URL = 'https://api.step365.app/api'
const BASE_URL = 'https://api.step365.app'

// const API_BASE_URL = 'https://staging-api.soniagarmar.com/api'
// const BASE_URL = 'https://staging-api.soniagarmar.com'

export default {
  API_BASE_URL: API_BASE_URL,
  BASE_URL: BASE_URL
}
