import axios from 'axios'
import Config from '../config/config'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    userStations: null,
    userStationsCode: [],
    authError: null,
    userPartners: null,
    userPartnersCode: [],
    loading: false
  },

  getters: {
    loading (state) {
      return state.loading
    },

    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },

    userStations (state) {
      return state.userStations
    },

    userStationsCode (state) {
      return state.userStationsCode
    },

    userPartners (state) {
      return state.userPartners
    },

    userPartnersCode (state) {
      return state.userPartnersCode
    },

    authError (state) {
      return state.authError
    }
  },

  mutations: {
    SET_LOADING (state, value) {
      state.loading = value
    },

    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    },

    SET_USER_STATIONS (state, value) {
      state.userStations = value
    },

    SET_USER_STATIONS_CODE (state, value) {
      state.userStationsCode = value
    },

    SET_USER_PARTNERS (state, value) {
      state.userPartners = value
    },

    SET_USER_PARTNERS_CODE (state, value) {
      state.userPartnersCode = value
    },

    SET_AUTH_ERROR (state, value) {
      state.authError = value
    }
  },

  actions: {
    async signIn ({ commit, dispatch }, credentials) {
      // console.log('base url', Config.BASE_URL)
      await axios.get(`${Config.BASE_URL}/sanctum/csrf-cookie`)
      await axios.post(`${Config.BASE_URL}/login`, credentials).then((response) => {
        console.log('User signed in!')
        return dispatch('getUser')
      }).catch((error) => {
        console.log('error', error)

        const errorMsg = error.response.status === 401 || error.response.status === 422
          ? 'Nombre de usuario y/o contraseña incorrectos'
          : 'Se ha producido un error.Por favor, inténtalo de nuevo'

        commit('SET_AUTH_ERROR', errorMsg)
      })
    },

    async signOut ({ dispatch }) {
      await axios.post(`${Config.BASE_URL}/logout`)
      return dispatch('getUser')
    },

    getUser ({ commit, dispatch }) {
      return axios.get(`${Config.BASE_URL}/api/user`).then((response) => {
        // console.log('USER', response.data.id)
        if (response.data.role === 'partner') {
          return axios.get(`${Config.BASE_URL}/api/partner/${response.data.id}`)
            .then((partnerResponse) => {
              // console.log('partner', partnerResponse)
              // eslint-disable-next-line eqeqeq
              if (partnerResponse.data.active == false) {
                console.log('User NOT ACTIVE!')
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', null)
                commit('SET_AUTH_ERROR', 'Usuario desactivado')
              } else {
                console.log('User signed in!')
                commit('SET_AUTHENTICATED', true)
                commit('SET_USER', response.data)

                dispatch('userStations')
                dispatch('userPartners')
              }
            })
        } else {
          console.log('User signed in!')
          commit('SET_AUTHENTICATED', true)
          commit('SET_USER', response.data)

          dispatch('userStations')
          dispatch('userPartners')
        }
      }).catch((error) => {
        console.log('User NOT signed in!', error)
        // if (error.response) {
        //   console.log(error.response.data)
        //   console.log(error.response.status)
        //   console.log(error.response.headers)
        // }
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)

        const errorMsg = error.response.status === 401
          ? ''
          : 'Se ha producido un error.Por favor, inténtalo de nuevo'
        commit('SET_AUTH_ERROR', errorMsg)
      })
    },

    userStations ({ commit }) {
      return axios.get(`${Config.BASE_URL}/api/user-stations`).then((response) => {
        commit('SET_USER_STATIONS', response.data)

        const userStationsCodes = []
        response.data.forEach((station, index) => {
          userStationsCodes[index] = station.code
        })
        commit('SET_USER_STATIONS_CODE', userStationsCodes)
      }).catch((error) => {
        commit('SET_USER_STATIONS', null)
        console.log(error.response)
      })
    },

    userPartners ({ commit }) {
      commit('SET_LOADING', true)
      return axios.get(`${Config.BASE_URL}/api/user-partners`).then((response) => {
        commit('SET_USER_PARTNERS', response.data)
        commit('SET_LOADING', false)
      }).catch((error) => {
        commit('SET_USER_PARTNERS', null)
        commit('SET_LOADING', false)
        console.log(error.response)
      })
    }
  }

}
