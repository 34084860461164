/* eslint-disable eqeqeq */
/* eslint-disable eqeqeq */
<template>
  <b-container fluid class="content-wrapper" >
    <b-row>
      <h1 class="title">Colaboradores</h1>
    </b-row>

    <div v-if="loading"  class="loader">
      <b-spinner medium></b-spinner>
    </div>

    <div v-else id="show-data">
        <div v-if="loadingEnable"  class="loader">
          <b-spinner medium ></b-spinner>
        </div>

        <b-row class="mt-6 mb-3 d-flex justify-content-center">
            <b-table striped hover responsive
            :items="partners"
            :fields="fields"
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear="">
              <template #cell(id)="data" >
               {{ data.value }}
              </template>

              <template #cell(name)="data" >
                {{ data.value }}
              </template>

              <template #cell()="data">
                <span :class="activeClass(data.item.active)">{{ activeText( data.item.active)}}</span>

                <b-button size="sm" variant="primary"  @click="toggleActive(data.item.id, !data.item.active)">
                      {{ buttonText(data.item.active) }}
                </b-button>
              </template>
            </b-table>
        </b-row>
    </div>
  </b-container>
</template>

<script>
import Config from '../config/config'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'partners',
  data () {
    return {
      key: 1, // used to reload the table
      partners: [],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'active', label: 'Activo', sortable: true }
      ],
      loadingEnable: false,
      loading: false,
      excelColumns: [
        { field: 'id', label: 'Id' },
        { field: 'name', label: 'nombre' },
        { field: 'active', label: 'activo' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userPartners: 'auth/userPartners',
      user: 'auth/user'
      // loading: 'auth/loading'
      // userStationsCode: 'auth/userStationsCode'
    }),
    invalidStartDateFeedback () {
      return 'Selecciona una fecha'
    },
    invalidEndDateFeedback () {
      return 'Selecciona una fecha'
    },
    formIsInvalid () {
      return Object.values(this.formValidation).indexOf(false) > -1 ||
         Object.values(this.formValidation).indexOf(null) > -1
    },
    isPartner () {
      return this.user && this.user.role === 'partner'
    }
  },
  watch: {
    // userPartners: function (newVal, oldVal) {
    //   // eslint-disable-next-line eqeqeq
    //   if (newVal != oldVal) {
    //     this.setPartners()
    //   }
    // }
  },
  mounted () {
    this.setPartners()
  },
  methods: {
    buttonText (active) {
      return active ? 'Desactivar' : 'Activar'
    },
    setPartners () {
      this.loading = true
      return axios.get(`${Config.BASE_URL}/api/user-partners`).then((response) => {
        response.data.forEach(partner => {
          this.partners.push({
            id: partner.id,
            name: partner.name,
            active: partner.active
          })
        // this.userStationsCodes.push(station.code)
        })
        // this.partners = response
        this.loading = false
      }).catch((error) => {
        this.loading = false
        console.log(error.response)
      })
    },
    toggleActive ($partnerId, $active) {
      this.loadingEnable = true
      axios
        .post(`${Config.BASE_URL}/api/partners/update-active`, { id: $partnerId, active: $active })
        .then((response) => {
          this.loadingEnable = false
          // this.$router.go(0)
          this.partners = []
          this.setPartners()

          // this.$root.$emit('bv::refresh::table', 'my-table')

          // this.$refs.table.refresh()
          // this.setPartners()
          // this.key += 1 // to relaod the table
          // this.$refs.table.refresh()
          // this.$router.go(this.$router.currentRoute)
        })
    },
    activeClass (active) {
      return active ? 'enabled' : 'disabled'
    },
    activeText (active) {
      return active ? 'Activo' : 'Inactivo'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

#qr-form {
  .row {
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 30px;
    }
  }
}

.custom-check-boxes {
  display: flex;
  flex-wrap: wrap;
 // ::v-deep input {margin: 5px !important; }
}

#boxes {
  width: 100px;
}

::v-deep input, button, select, optgroup, textarea {
    margin: 5px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// ::v-deep input[type=checkbox]:checked:after {
//     content: "\2714";
//     background-color: red;
//  }

.margin-top {
  margin-top:30px;

  @include tablet {
   margin-top:0
  }
}
.sub-input {
  margin-left: 15px;
  font-size: 14px
}

#total {
  font-size: 18px;
  font-weight: bold
}

.btn.btn-primary.btn-sm{
  display: inline-block;
  margin-left: 10px;
}

.enabled {
  color: green
}

.disabled {
  color: red
}

.another-button.btn-primary {
  border-color: $primary;
  color: $primary!important;
  background-color: #fff;
}

.loader {
  position: fixed;
  top: 200px;
  width: 100%;
  height: 100vh;
  background-color: white;
  opacity: 0.5;
  text-align: center;
}

</style>
