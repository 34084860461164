/* eslint-disable eqeqeq */
/* eslint-disable eqeqeq */
<template>
  <b-container fluid class="content-wrapper">
    <div id="qr-form" v-if="!showTable">
      <b-row>
        <h1  class="title">Códigos QR canjeados</h1>
      </b-row>

      <b-form @submit="onSubmit">
        <b-row v-if="!isPartner">
          <b-col lg="8">
            <b-form-group label="Selecciona qué QR quieres ver">
              <b-form-radio v-model="form.qrOwner" name="companyQrs" value="all"> Todos </b-form-radio>
              <b-form-radio v-model="form.qrOwner" name="companyQrs" value="only-partners">Solo colaboradores </b-form-radio>

              <div class="d-flex flex-column sub-input" v-if="form.qrOwner ==='only-partners'">
                  <b-form-group>
                    <b-form-checkbox
                      id="all-partners"
                      v-model="form.allPartners"
                      name="all-partners"
                      value="true"
                    > Todos los colaboradores
                    </b-form-checkbox>
                  </b-form-group>

                  <div v-if="!form.allPartners">
                    <label  for="partners" class="col-auto pl-0">Selecciona un colaborador </label>
                    <b-form-checkbox-group
                      v-model="form.partnersCodes"
                      id="partners-ids"
                      :options="partners"
                    >
                    </b-form-checkbox-group>
                  </div>
            </div>

            <b-form-radio v-model="form.qrOwner" name="companyQrs" value="only-company">Solo propios</b-form-radio>
            </b-form-group>

          </b-col>
        </b-row>

        <b-row>
          <b-col lg="4">
              <b-form-group
              label="Fecha inicial"
              :invalid-feedback="invalidStartDateFeedback"
              :state="formValidation.startDate"
            >
              <b-form-datepicker
                id="start-time"
                v-model="form.startDate"
                :state="formValidation.startDate"
                no-close-button
                placeholder=""
                locale="es"
              ></b-form-datepicker>
              </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group
              label="Fecha Final"
              :invalid-feedback="invalidEndDateFeedback"
              :state="formValidation.endDate"
            >
              <b-form-datepicker
                id="end-time"
                v-model="form.endDate"
                :state="formValidation.endDate"
                locale="es"
                placeholder=""
                no-close-button
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row  v-if="!isPartner">
          <b-col lg="8">
            <b-form-group>
              <b-form-checkbox
                id="all-stations"
                v-model="form.allStations"
                name="all-stations"
                value="true"
              > Todos los centros de lavado
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex" v-if="!form.allStations" >
              <label for="stations" class="col-auto pl-0">Selecciona centro de lavado </label>
                <b-form-checkbox-group
                v-model="form.stationCodes"
                id="station-ids"
                :options="stations"
              >
              </b-form-checkbox-group>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col lg="8" class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" :disabled="formIsInvalid" >
              <b-spinner small v-if="loading"></b-spinner>
               {{ buttonText }}
              </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div id="show-data" v-else>
         <b-row class="mt-6 mb-3 d-flex justify-content-center">
          <!-- <div class="buttons">
             <b-button variant="primary" @click="getExcel">
                <b-spinner  v-if="loading" small></b-spinner>
                <b-icon v-else icon="cloud-download" aria-hidden="true"></b-icon>
            </b-button>
          </div> -->

          <div class="buttons">
             <div class="excel">
                <vue-excel-xlsx
                    :data="redeemedCodes"
                    :columns="excelColumns"
                    :file-name="'qr-canjeados'"
                    :file-type="'xlsx'"
                    :sheet-name="'hoja1'"
                    >
                    <b-icon icon="cloud-download" aria-hidden="true"></b-icon> Excel
               </vue-excel-xlsx>
             </div>

            <b-button class="another-button" variant="primary" @click="toggleTable">
                Hacer otra consulta
            </b-button>
          </div>
        </b-row>

        <b-row>

        </b-row>

        <b-row class="mt-6 mb-3 d-flex justify-content-center">

            <div id="total">
              <p> TOTAL: {{totalAmount}} € </p>
            </div>

            <b-table striped hover responsive
            :items="redeemedCodes"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear=""
            ></b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              class="custompagination"
            ></b-pagination>
        </b-row>
    </div>
  </b-container>
</template>

<script>
import Config from '../config/config'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'qr-show-redeemed',
  data () {
    return {
      redeemedCodes: {},
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'qr_code', label: 'Código', sortable: true },
        { key: 'amount', label: 'Importe', sortable: true },
        { key: 'station', label: 'Centro', sortable: true },
        { key: 'created_at', label: 'Fecha', sortable: true },
        { key: 'created_by_partner', label: 'Colaborador', sortable: true }
      ],
      perPage: 50,
      currentPage: 1,
      loading: false,
      showTable: false,
      totalAmount: 0,
      form: {
        allStations: true,
        stationCodes: [],
        partnersCodes: [],
        startDate: '',
        endDate: '',
        qrOwner: 'all',
        allPartners: true
      },
      stations: [
      ],
      partners: [
      ],
      // userStationCodes: [],
      formValidation: {
        startDate: true,
        endDate: true
      },
      excelColumns: [
        { field: 'id', label: 'Id' },
        { field: 'qr_code', label: 'Código' },
        { field: 'amount', label: 'Importe' },
        { field: 'station_id', label: 'Centro' },
        { field: 'created_at', label: 'Fecha' },
        { field: 'created_by_partner', label: 'Colaborador' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userStations: 'auth/userStations',
      userPartners: 'auth/userPartners',
      user: 'auth/user'
      // userStationsCode: 'auth/userStationsCode'
    }),
    rows () {
      return this.redeemedCodes.length
    },
    buttonText () {
      return this.loading
        ? 'Cargando...'
        : 'Obtener datos'
    },
    invalidStartDateFeedback () {
      return 'Selecciona una fecha'
    },
    invalidEndDateFeedback () {
      return 'Selecciona una fecha'
    },
    formIsInvalid () {
      return Object.values(this.formValidation).indexOf(false) > -1 ||
         Object.values(this.formValidation).indexOf(null) > -1
    },
    isPartner () {
      return this.user && this.user.role === 'partner'
    }
  },
  watch: {
    'form.startDate': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isStartDateValid()
      }
    },
    'form.endDate': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isEndDateValid()
      }
    },
    userStations: function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.setStations()
      }
    },
    userPartners: function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.setPartners()
      }
    }
  },
  methods: {
    isStartDateValid () {
      this.formValidation.startDate = this.form.startDate != null
      // return this.form.expiration != this.formDefault.expiration && this.form.expiration != ''
    },
    isEndDateValid () {
      this.formValidation.endDate = this.form.endDate != null
    },
    createValidStationCodesArray () {
      const stationCodes = [0, 0, 0]
      // console.log('ues', this.userStationsCode)
      // const stationCodes = [...this.userStationsCode]

      this.form.stationCodes.forEach((element, index) => {
        stationCodes[index] = element
      })
      this.form.stationCodes = [...stationCodes]
      // console.log('valid STATIONS', this.form.stationCodes)
    },
    setDefaultStartDate () {
      var today = new Date()
      today.setDate(today.getDate() - 30)
      this.form.startDate = today
    },
    setDefaultEndDate () {
      var today = new Date()
      today.setDate(today.getDate())
      this.form.endDate = today
    },
    setStations () {
      if (this.userStations) {
        this.userStations.forEach(station => {
          this.stations.push({
            text: station.station_name,
            value: station.code
          })
          // this.userStationsCodes.push(station.code)
        })

        // console.log('stt', this.stations)
      }

      return this.formStations
    },
    setPartners () {
      if (this.userPartners) {
        this.userPartners.forEach(partner => {
          this.partners.push({
            text: partner.name,
            value: partner.user_id
          })
        })

        // console.log('PARTNERS', this.partners)
      }
    },
    onSubmit (event) {
      event.preventDefault()
      this.isStartDateValid()
      this.isEndDateValid()
      this.loading = true

      if (Object.values(this.formValidation).indexOf(false) > -1) {
        console.log('invalid')
      } else {
        this.formIsValid = true
        this.createValidStationCodesArray()

        axios
          .post(`${Config.API_BASE_URL}/qr/get-codes`, this.form)
          .then((response) => {
            this.showTable = true
            this.loading = false
            this.redeemedCodes = response.data.codes

            this.redeemedCodes.forEach((code, index) => {
              this.redeemedCodes[index].station = this.redeemedCodes[index].station.station_name
            })

            this.totalAmount = parseInt(response.data.amount) / 100
          })
      }
    },
    toggleTable () {
      this.showTable = false
      this.loading = false
    },
    getExcel () {

    }
  },
  mounted () {
    this.setDefaultStartDate()
    this.setDefaultEndDate()
    this.setStations()
    this.setPartners()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

#qr-form {
  .row {
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 30px;
    }
  }
}

.custom-check-boxes {
  display: flex;
  flex-wrap: wrap;
 // ::v-deep input {margin: 5px !important; }
}

#boxes {
  width: 100px;
}

::v-deep input, button, select, optgroup, textarea {
    margin: 5px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// ::v-deep input[type=checkbox]:checked:after {
//     content: "\2714";
//     background-color: red;
//  }

.margin-top {
  margin-top:30px;

  @include tablet {
   margin-top:0
  }
}
.sub-input {
  margin-left: 15px;
  font-size: 14px
}

#total {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px
}
.buttons {
  .btn {
    display: inline-block;
    margin: 0 20px;
  }
}

.excel {
  margin-top: 20px;
  display: inline-block;

  button {
    border-color: $primary;
    color: #fff;
    background-color:  $primary!important;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    border-width: 0;
  }
}

.another-button.btn-primary {
  border-color: $primary;
  color: $primary!important;
  background-color: #fff;
}

::v-deep .custompagination .page-item.active .page-link {
  background-color: $primary!important;
  border-color: $primary!important;
}
</style>
